/*ADD SELF HOST FONTS */
@font-face {
  font-family: "Circular Std Medium";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("assets/fonts/Circular-Std-Medium.woff2") format("woff2");
}

@font-face {
  font-family: "Euclid Circular";
  font-display: swap;
  src: url("assets/fonts/EuclidCircularA-Regular.woff2") format("woff2");
}

@font-face {
  font-family: "Euclid Circular";
  font-display: swap;
  font-weight: 300;
  src: url("assets/fonts/EuclidCircularA-Light.woff2") format("woff2");
}

@font-face {
  font-family: "Euclid Circular";
  font-display: swap;
  font-weight: 500;
  src: url("assets/fonts/EuclidCircularA-Medium.woff2") format("woff2");
}

@font-face {
  font-family: "Euclid Circular";
  font-display: swap;
  font-weight: 600;
  src: url("assets/fonts/EuclidCircularA-SemiBold.woff2") format("woff2");
}

@font-face {
  font-family: "Euclid Circular";
  font-display: swap;
  font-weight: 700;
  src: url("assets/fonts/EuclidCircularA-Bold.woff2") format("woff2");
}

@font-face {
  font-family: "Euclid Circular";
  font-display: swap;
  font-style: italic;
  src: url("assets/fonts/EuclidCircularA-Italic.woff2") format("woff2");
}

@font-face {
  font-family: "Euclid Circular";
  font-display: swap;
  font-weight: 500;
  font-style: italic;
  src: url("assets/fonts/EuclidCircularA-MediumItalic.woff2") format("woff2");
}

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  font-size: 62.5%;
  font-family: "Euclid Circular", sans-serif;
  scroll-behavior: smooth;
  background-color: #f8f8f8;
}

.container {
  width: 100%;
  height: 100%;
}

/* Table pagination menu items */
.MuiTablePagination-menuItem {
  font-size: 1.2rem !important;
}

.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border-color: #e6e6e6 !important;
  border: 1px solid;
}

.MuiSelect-select {
  padding: 0 26px 0 10px !important;
}
.kpNhUO {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 1rem !important;
  font-size: 1.5rem !important;
  overflow: hidden !important;
}

.kpNhUO p {
  height: 100px !important;
  max-height: 200px;
  overflow: scroll;
}
.css-133vrgo-MuiInputBase-root-MuiOutlinedInput-root.Mui-disabled {
  background-color: #fafafa !important;
}

span {
  font-weight: 400 !important;
  /* font-size: 1.4rem !important; */
}

td {
  font-weight: 400 !important;
  font-size: 1.3rem !important;
}
input::placeholder {
  font-size: 14px !important;
  font-weight: 400 !important;
}
input::placeholder {
  font-weight: 400 !important;
}
.MuiTablePagination-menuItem {
  font-size: 1.2rem !important;
}

li {
  font-weight: 400;
}
.dropdown {
  position: relative;
  /* display: inline-block; */
}
.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled {
  -webkit-text-fill-color: #4f4f4f !important;
  fill: #4f4f4f;
  font-size: clamp(0.8rem, 2vw, 1.6rem) !important;
  font-weight: normal !important;
}

/* .dropdown-content {
  display: none;
  position: absolute;
  background-color: #f6f6f6;
  min-width: 230px;
  overflow: auto;
  border: 1px solid #ddd;
  z-index: 1;
}

.dropdown-content option {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
} */

.form-control {
  width: 100% !important;
  height: 5rem;
  padding: 1rem;
  font-family: "Euclid Circular";
  font-size: 2rem;
}
.form-control:active,
.form-control:focus,
.rdt,
.rdtOpen.rdt {
  border: none !important;
}
.rdt {
  /* background-color: red !important; */
  /* border: 10px solid red !important; */
}

[role="cell"] button.MuiButtonBase-root {
  font-size: 2rem;
}
span.MuiTypography-caption {
  font-size: 2rem;
}
